<template>
  <div
    v-if="showCustomContent"
    class="seo-content"
    :class="{
      'bg-grey': route.fullPath === '/' || route.fullPath === `/${locale}`,
    }"
  >
    <BaseCustomHTMLContent
      class="content container"
      :custom-html-content="data?.websitePage?.content"
    />
  </div>
</template>

<script setup lang="ts">
import { useWebsitePageQuery } from "~/graphql/generated";

const getRouteBaseName = useRouteBaseName();
// Watch for route changes to set the correct page title on navigating
const route = useRoute();

const isCustomPage = computed(() => getRouteBaseName(route) === "slug");

// Retrieve the SEO text block for the current path
const variables = computed(() => ({ websitePage: { path: route.path } }));
const { data, executeQuery } = await useWebsitePageQuery({
  variables,
  pause: isCustomPage,
});

// Watch the locale and refetch the page when it changes
const { locale } = useI18n();
watch(locale, () => {
  executeQuery({ fetchPolicy: "network-only" });
});

const showCustomContent = computed(() => {
  if (isCustomPage.value) return false;
  if (!data.value?.websitePage?.content) return false;

  // The content editor on the CMS will still return an empty paragraph when deleting content.
  // So we consider the length of that as the minimum length for the content to be considered valid.
  if (data.value.websitePage.content.length <= "<p></p>".length) return false;

  return true;
});

useHead({
  ...(data.value?.websitePage?.title && {
    title: data.value?.websitePage?.title,
  }),
  ...(data.value?.websitePage?.metaDescription && {
    meta: [
      {
        name: "description",
        content: data.value.websitePage.metaDescription,
      },
    ],
  }),
});
</script>

<style lang="scss" scoped>
.content {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.bg-grey {
  background-color: $light-95;
}
</style>
