<template>
  <NuxtLink :to="localePath('/app')">
    <BaseButton class="secondary" icon="phone_iphone">{{
      $t("navbar.download-app")
    }}</BaseButton>
  </NuxtLink>
</template>

<script setup lang="ts">
const localePath = useLocalePath();
</script>
