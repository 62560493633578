<template>
  <div class="navbar-top">
    <div class="container">
      <NavbarLogo class="logo small-text-mobile" />
      <NavbarSearch class="search-field" />

      <LocationSelector class="hide-small" />
      <BaseIcon
        class="light interactive hide-medium-up"
        :icon="location ? 'location_on' : 'location_off'"
        @click="locationMenuOpen = !locationMenuOpen"
      />

      <DownloadAppButton class="hide-small" />
      <NavbarLanguage
        v-if="host === 'promopromo'"
        class="language-selector hide-small"
      />
      <BaseIcon
        v-if="!isMenuOpen && !isLocationMenuOpen"
        icon="menu"
        class="light interactive hide-medium-up"
        @click="data = true"
      />
      <BaseIcon v-else icon="close" class="light interactive hide-medium-up" />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  isMenuOpen: boolean;
  isLocationMenuOpen: boolean;
}>();

const emit = defineEmits(["update:isMenuOpen", "update:isLocationMenuOpen"]);
const data = useVModel(props, "isMenuOpen", emit);
const locationMenuOpen = useVModel(props, "isLocationMenuOpen", emit);

const location = useLocationState();

// Close the menu on route change
const route = useRoute();
watch(
  () => route.path,
  () => {
    data.value = false;
  }
);

const host = useCurrentHost();
</script>

<style lang="scss" scoped>
.navbar-top {
  background-color: var(--primary);
  width: 100%;

  height: 88px;
  position: relative;

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 3.5rem;

    @include for-small-screen {
      gap: 1rem;

      .logo {
        margin-right: 1rem;
        flex: 1;
      }
    }

    @include for-medium-screen-up {
      .search-field {
        flex: 1;
      }
    }
  }

  .language-selector {
  }
}
</style>
