<template>
  <nuxt-link
    class="sub-navigation-item"
    :to="localeRoute(navItem)"
    :class="{ 'router-link-active': isItemActive }"
  >
    {{ $t(`pages.${navItem}`) }}
  </nuxt-link>
</template>

<script setup lang="ts">
const props = defineProps<{ navItem: string }>();
const localeRoute = useLocaleRoute();
const routeBaseName = useRouteBaseName();
const currentRoute = useRoute();

// 'Child' routes need the router-link-active class as well
const isItemActive = computed(() => {
  const currentRouteBase = routeBaseName(
    useRouter().resolve(currentRoute.path)
  );
  if (!currentRouteBase) return false;
  return currentRouteBase.includes(props.navItem);
});
</script>

<style lang="scss" scoped>
// Mobile styles (small screens)
@include for-small-screen {
  .sub-navigation-item {
    color: $light-100;
  }
}

// Tablet landscape and up styles (medium screens)
@include for-medium-screen-up {
  .sub-navigation-item {
    color: $dark-100;
    font-size: 1.125rem;
    padding: 0.25rem 0.75rem;
    margin-left: -0.75rem;
    margin-top: -0.25rem;

    &.router-link-active {
      background-color: var(--primary);
      border-radius: 200px;

      color: $light-100;
    }
  }
}
</style>
