<template>
  <BaseIcon
    v-if="!showPlacesInput"
    class="light size-26 interactive"
    :icon="location ? 'location_on' : 'location_off'"
    @click="setLocation"
  />
  <div v-else ref="locationInput" class="place-input">
    <LocationAutocompleteInput class="location-autocomplete" />
  </div>
</template>

<script setup lang="ts">
const location = useLocationState();
const showPlacesInput = ref(false);

const setLocation = () => {
  // Try to get the location via the browser geolocation API with vueuse
  const { coords, error } = useGeolocation();

  // Watch for coordinate updates
  watch(coords, (newCoords) => {
    location.value = { lat: newCoords.latitude, long: newCoords.longitude };
  });

  watch(
    () => error.value,
    (error) => {
      if (error) {
        // If the user denies the location request, we use the google places autocomplete input
        showPlacesInput.value = true;
      }
    }
  );
};

// Close the places input on click outside
const locationInput = ref<HTMLElement | null>(null);
onClickOutside(locationInput, () => {
  if (!showPlacesInput.value) return;
  showPlacesInput.value = false;
});

// Close if location changes
watch(location, (newVal, oldVal) => {
  if (newVal && !oldVal) showPlacesInput.value = false;
});
</script>

<style lang="scss" scoped>
.place-input {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
  .location-autocomplete {
    width: 100%;
  }
}
</style>
