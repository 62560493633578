<template>
  <nav
    ref="navMenu"
    class="location-navigation"
    :class="{ 'is-open': isMenuOpen }"
  >
    <div class="container mt-1 mb-1">
      <BaseText class="text-large text-light">
        {{ $t("navbar.location.label") }}
      </BaseText>
      <LocationAutocompleteInput class="location-autocomplete" />
    </div>
  </nav>
</template>

<script setup lang="ts">
const props = defineProps<{ isMenuOpen: boolean }>();
const emits = defineEmits(["close"]);

// Close the menu on click outside
const navMenu = ref<HTMLElement | null>(null);
onClickOutside(navMenu, () => {
  if (props.isMenuOpen) emits("close");
});

const location = useLocationState();

watch(
  () => props.isMenuOpen,
  (isMenuOpen) => {
    if (isMenuOpen) setLocation();
  }
);

const setLocation = () => {
  // Try to get the location via the browser geolocation API with vueuse
  const { coords } = useGeolocation();

  // Watch for coordinate updates
  watch(coords, (newCoords) => {
    location.value = { lat: newCoords.latitude, long: newCoords.longitude };
  });
};

// Close if location changes
watch(location, (newVal, oldVal) => {
  if (newVal && !oldVal) emits("close");
});
</script>

<style lang="scss" scoped>
.location-navigation {
  display: none;
}

.location-autocomplete {
  width: 100%;
}

// Mobile styles (small screens)
@include for-small-screen {
  .location-navigation {
    position: absolute;
    background-color: var(--primary);
    width: 100%;
    top: 100%;

    &.is-open {
      display: block;
      z-index: 2;

      .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
      }
    }
  }
}
</style>
