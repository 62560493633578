<template>
  <div class="navbar">
    <NavbarTop
      v-model:isMenuOpen="isMenuOpen"
      v-model:isLocationMenuOpen="isLocationMenuOpen"
    />
    <NavbarSubNavigation
      :is-menu-open="isMenuOpen"
      @close="isMenuOpen = false"
    />
    <NavbarLocationMenu
      :is-menu-open="isLocationMenuOpen"
      @close="isLocationMenuOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
const isMenuOpen = ref(false);
const isLocationMenuOpen = ref(false);
</script>

<style lang="scss" scoped>
.navbar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
}
</style>
