<template>
  <div class="layout-default">
    <Navbar />
    <div class="page">
      <slot />
    </div>
    <SEOItem />
    <Footer class="footer" />
  </div>
</template>

<style lang="scss" scoped>
.layout-default {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100dvh;
  position: relative;
  touch-action: pan-y;

  .page {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .footer {
    margin-top: auto;
  }
}
</style>
