<template>
  <div class="navbar-search">
    <SearchInput
      ref="search"
      class="search-input"
      :class="{ 'is-open': isSearchOpen }"
      @submit="isSearchOpen = false"
    />

    <BaseIcon
      icon="search"
      class="search-toggle light interactive hide-medium-up"
      :class="{ 'is-open': isSearchOpen }"
      @click="openSearch"
    />
  </div>
</template>

<script setup lang="ts">
const isSearchOpen = ref(false);

const search = ref<HTMLElement | null>(null);
onClickOutside(search, () => {
  isSearchOpen.value = false;
});

const openSearch = async () => {
  isSearchOpen.value = true;
  await nextTick();
  search.value?.focus();
};
</script>

<style lang="scss" scoped>
.navbar-search {
  padding: 10px 0;
  height: fit-content;
  display: flex;
  align-items: center;
}

@include for-small-screen {
  .search-input {
    position: absolute;
    left: 1rem;
    max-width: calc(100% - 2rem);

    &:not(.is-open) {
      display: none;
    }
  }

  .search-toggle.is-open {
    display: none;
  }
}
</style>
